.nav-bg {
  background-color: var(--navbar-scroll-color); /* Semi-transparent background */
  backdrop-filter: blur(4px); /* Blur effect */
  -webkit-backdrop-filter: blur(4px); /* Safari support */
}
.nav-bg-scroll {
  background-color: var(--navbar-fixed);
}
body {
  overflow-y: scroll;
}
