.buttonEnable {
  font-size: small;
  color: black;
  background-color: var(--mui-palette-secondary-main);
}

.buttonDisable {
  font-size: small;
  color: black;
  background-color: var(--mui-palette-grey-main);
}

.gm-style-iw button[title='Close'] {
  display: none !important;
}

.cards-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust as needed */
}

.cards-container > div {
  flex: 1 1 calc(50% - 10px); /* 50% width minus the gap divided by 2 */
  box-sizing: border-box;
  max-width: calc(50% - 10px);
}

/* For smaller screens, we can still make each card take the full width */
@media (max-width: 768px) {
  .cards-container > div {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
.radio-buttons {
  margin-block: 2px;
  display: flex;
  border: none;
  gap: 10px;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 12px;
  background: transparent;
  border-bottom: 1px solid #e5e5e5;
}

@media (max-width: 700px) {
  .radio-buttons {
    width: 100%;
  }
}
