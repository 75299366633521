.SaveBtn {
  position: absolute;
  bottom: 23%;
  right: 4%;
}

@media (min-width: 750px) {
  .SaveBtn {
    position: absolute;
    bottom: 23%;
    right: 2%;
  }
}

@media (min-width: 950px) {
  .SaveBtn {
    position: absolute;
    bottom: 2%;
    right: 2%;
  }
}
