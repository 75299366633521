.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.circle-loader {
  border: 4px solid #f7f5ec; /* lighter outer border */
  border-radius: 50%;
  border-top: 4px solid #e9e040; /* yellow spinning part */
  width: 25px; /* original width */
  height: 25px; /* original height */
  margin: 2px; /* gap around the loader */
  box-sizing: border-box; /* include border in dimensions */
  animation: spin 0.8s linear infinite; /* faster, smoother animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
