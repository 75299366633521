.drawerheight {
  height: 49vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 750px) {
  .drawerheight {
    height: 52vh;
  }
}
@media (min-width: 1050px) {
  .drawerheight {
    height: 40vh;
    justify-content: space-around;
  }
}
