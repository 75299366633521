.container {
  padding: 16px;
}
.tagline {
  font-size: 14px;
}
.name {
  font-size: 14px;
}

@media (min-height: 890px) {
  .name {
    font-size: 16px;
  }
  .tagline {
    font-size: 16px;
  }
}

@media (max-width: 450px) {
  .container {
    padding: 15px;
  }
  .name {
    font-size: 12px;
  }
  .tagline {
    font-size: 13px;
  }
}

@media (max-width: 370px) {
  .container {
    padding: 10px;
  }
  .name {
    font-size: 11px;
  }
  .tagline {
    font-size: 13px;
  }
}
