.address-text {
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media (min-width: 1100px) {
  .address-text {
    max-width: 100%;
  }
}
@media (min-width: 750px) {
  .address-text {
    max-width: 60%;
  }
}
