.calendar {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 0rem;
  margin-top: 1rem;
  position: relative;
}

.navigation {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 8px;
  color: #474747;
  border: 1px solid #d9d9d9;
}

.navigation div {
  display: inline-block;
  width: 18%;
}

.navigation .monthAndYear {
  text-align: center;
  width: 64%;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  padding: 4px;
  font-size: 1rem; /* Slightly increase font size for prominence */
}

.navigation .back {
  text-align: center;
  cursor: pointer;
}

.navigation .back.disabled {
  color: #a3a3a3; /* Grey out */
  cursor: not-allowed;
  pointer-events: none; /* Disable interaction */
}

.navigation .forward {
  text-align: center;
  cursor: pointer;
}
