.CloseBtn {
  position: absolute;
  bottom: 23%;
}

.SaveBtn {
  position: absolute;
  bottom: 23%;
  right: 4%;
}

@media (min-width: 750px) {
  .CloseBtn {
    position: absolute;
    bottom: 23%;
  }

  .SaveBtn {
    position: absolute;
    bottom: 23%;
    right: 2%;
  }
}
