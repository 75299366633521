.scrollable-container {
  height: 65vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.content-container {
  height: 60vh !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@media (min-width: 820px) {
  .scrollable-container {
    height: 50vh;
  }
}

@media (min-width: 750px) {
  .content-container {
    margin-top: 50px;
    height: 50vh !important;
    justify-content: space-between;
  }
  .metrics-container {
    height: 75vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-height: 740px) {
  .scrollable-container {
    height: 450px;
  }
}
